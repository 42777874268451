<template>
  <div class="dialog">
    <div class="dialog-content">
      <div class="dialog-content-wrapper announcement">
        <div v-html="annContent"></div>
        <div class="footer text-center">
          <b-button class="close-btn btn-h3-xs"
                    size="sm"
                    variant="warning"
                    @click="closeAnnouncement">
            Close
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import "quill-emoji/dist/quill-emoji.css";

  export default {
    name  : 'Announcement',
    props : {
      id      : Number,
      content : String,
    },
    data() {
      return {}
    },
    computed : {
      annContent() {
        return this.content.replace(/\?/gi, '');
        // return this.data[0].content.replace(/\?/gi, '');
      },
    },
    methods : {

      // Close announcement modal and set announcement as read
      closeAnnouncement() {
        this.$http.post('api/announcement/read', {
          announcementId : this.id,
        }).then(() => {
          this.$emit('close');
        }).catch(() => {
          this.$emit('close');
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/announcement/announcement";
</style>